import { useState } from "react";
import ReactPlayer from "react-player";
import { MediaButton, VideoWrapper } from "./styles";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

interface VideoPlayerProps {
  url: string;
  onProgress?: any;
}

const VideoPlayer = ({ url, onProgress }: VideoPlayerProps) => {
  const [playing, setPlaying] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const { branding } = useSpiritualAssessmentContext();

  const handleShowButton = (value: boolean) => {
    setShowButton(value);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  return (
    <>
      <VideoWrapper
        onMouseEnter={() => handleShowButton(true)}
        onMouseLeave={() => handleShowButton(false)}
      >
        {!playing ? (
          <MediaButton color={branding.primaryColor}>
            <svg
              version="1.1"
              id="playButton"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 300 300"
              onClick={() => handlePlayPause()}
            >
              <g>
                <circle className="circle" cx="149.63" cy="149.54" r="150" />
              </g>
              <path
                className="play"
                d="M201.7,139.43c3.53,2.17,5.68,6,5.68,10.12s-2.15,7.95-5.68,9.9l-71.06,43.43c-3.66,2.44-8.23,2.54-11.97,0.44
            c-3.74-2.1-6.05-6.05-6.05-10.34v-86.86c0-4.28,2.31-8.23,6.05-10.33c3.74-2.1,8.31-2.01,11.97,0.22L201.7,139.43z"
              />
            </svg>
          </MediaButton>
        ) : (
          showButton && (
            <MediaButton color={branding.primaryColor}>
              <svg
                version="1.1"
                id="pauseButton"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 300 300"
                onClick={() => handlePlayPause()}
              >
                <g>
                  <circle className="circle" cx="149.63" cy="149.54" r="150" />
                </g>
                <path
                  className="pause"
                  d="M182.49,93.35h-9.28c-7.69,0-13.92,6.23-13.92,13.66v83.54c0,7.69,6.23,13.92,13.92,13.92l9.28,0.52
	c7.69,0,13.92-6.23,13.92-13.92v-83.54C196.41,99.84,190.18,93.35,182.49,93.35z M126.79,93.35h-9.28
	c-7.69,0-13.92,6.23-13.92,13.92v83.54c0,7.95,6.23,14.19,13.92,14.19h9.28c7.69,0,13.92-6.23,13.92-13.92v-83.54
	C140.72,99.84,134.48,93.35,126.79,93.35z"
                />
              </svg>
            </MediaButton>
          )
        )}
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          playing={playing}
          controls={false}
          onProgress={onProgress}
          onPlay={handlePlay}
          onPause={handlePause}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", // Important: Hide download button
              },
            },
          }}
        />
      </VideoWrapper>
    </>
  );
};

export default VideoPlayer;
