import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import { useTranslations } from "hooks/useTranslations";
import { Input } from "./styles";

const InputText: React.FC<LibraryComponentProps> = ({
  slugKey,
  currentAnswer,
  onAnswerChange,
}) => {
  const { t } = useTranslations();
  return (
    <Input
      placeholder={t(slugKey)}
      value={currentAnswer ?? ""}
      onChange={(e) => onAnswerChange(e.target.value)}
    />
  );
};
export default InputText;
