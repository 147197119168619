import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import RadioInput from "library/radioInput/radioInput";
import RangeInput from "library/rangeInput/rangeInput";
// import RadioButton from "library/radioButton";
// import RadioSlider from "library/radioSlider";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SpiritualAssessmentResourceData } from "types/spiritualAssessment";
import { Wrapper } from "./styles";

export type RadioProps = {
  slugKey: string;
  config: SpiritualAssessmentResourceData;
  valueChecked: number;
  setValueChecked: Dispatch<SetStateAction<number>>;
};

const ComboRadio: React.FC<LibraryComponentProps> = (props) => {
  const [valueChecked, setValueChecked] = useState(0);
  useEffect(() => {
    props.onAnswerChange(props.config.options![valueChecked]);
  }, [valueChecked]);
  return (
    <Wrapper>
      <RadioInput
        {...{ valueChecked, setValueChecked, ...props }}
      ></RadioInput>
      <RangeInput
        {...{ valueChecked, setValueChecked, ...props }}
      ></RangeInput>
    </Wrapper>
  );
};

export default ComboRadio;
