import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Geolocation, useLocation } from "./useLocation";
import {
  PageInteractionHookResult,
  usePageInteractions,
} from "./usePageInteractions";
import { usePartner } from "./usePartner";

const interactiontrackingContext =
  createContext<InteractionTrackingContextResult>({} as any);

export const InteractionTrackingContext = (props: PropsWithChildren<{}>) => (
  <interactiontrackingContext.Provider value={useInteractionTracking()}>
    {props.children}
  </interactiontrackingContext.Provider>
);

export const useInteractionTrackingContext = () =>
  useContext(interactiontrackingContext);

export type InteractionTrackingContextResult = {
  partnerCode?: string;
  language?: string;
  location?: Geolocation;
  interactions: PageInteractionHookResult;
  setParams: React.Dispatch<{ partner?: string; language?: string }>;
};

type SpiritualAssessmentParams = {
  partner?: string;
  language?: string;
};

const defaultPartner = "ul";
const defaultLanguage = "en";

const useInteractionTracking = (): InteractionTrackingContextResult => {
  const [loadingParams, setLoadingParams] = useState(true);
  const [partnerCode, setPartnerCode] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const location = useLocation();
  const partner = usePartner(loadingParams, partnerCode!);
  const interactions = usePageInteractions(location, language, partner);

  const [params, setParams] = useState<SpiritualAssessmentParams | null>(null);

  useEffect(() => {
    if (params) {
      setPartnerCode(params["partner"] ?? defaultPartner);
      setLanguage(params["language"] ?? defaultLanguage);
      setLoadingParams(false);
    }
  }, [params]);

  return {
    partnerCode,
    language,
    location,
    interactions,
    setParams,
  };
};
