import { IconRadioButtonWrapper, RadioOption, Input } from "./style";
import WhatsAppIcon from "../../media/whatsapp-icon.svg";
import SmsIcon from "../../media/sms-icon.svg";
import EmailIcon from "../../media/envelope-icon.svg";
import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import { useTranslations } from "hooks/useTranslations";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

const icons = {
  whatsapp: WhatsAppIcon,
  sms: SmsIcon,
  email: EmailIcon,
};
type IconMapper = keyof typeof icons;
const IconRadioButton: React.FC<LibraryComponentProps> = ({
  config,
  slugKey,
  currentAnswer,
  onAnswerChange,
}) => {
  const { t } = useTranslations();
  const { branding } = useSpiritualAssessmentContext();
  const { options, variable } = config;

  return (
    <IconRadioButtonWrapper>
      {options &&
        options.map((option: string, i: number) => (
          <RadioOption key={i}>
            <label htmlFor={"icon-radio-button-" + { option }}>
              <img src={icons[option as IconMapper]} alt={option + "logo"} />
              {t(`${slugKey}.${option}`)}
            </label>
            <Input
              id={"icon-radio-button-" + { option }}
              type={"radio"}
              value={option}
              name={variable}
              color={branding.primaryColor}
              onChange={(e) => onAnswerChange(e.target.value)}
            />
          </RadioOption>
        ))}
    </IconRadioButtonWrapper>
  );
};

export default IconRadioButton;
