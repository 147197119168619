import { useEffect } from "react";
import { LayoutProps } from "components/layout/layout";
import Slide from "../../library/slide";
import Spinner from "../../library/spinner";
import { Paragraph, ParagraphWrapper } from "./styles";
import { useTranslations } from "hooks/useTranslations";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

const FullScreenLayout: React.FC<LayoutProps> = (props) => {
  const { t } = useTranslations();
  const { branding } = useSpiritualAssessmentContext();
  const slugKey = props.slugs.join(".");

  useEffect(() => {
    setTimeout(() => {
      props.onNextClick();
    }, 5000);
  }, []);

  return (
    <Slide>
      <ParagraphWrapper>
        <Paragraph>{t(`${slugKey}.title`)!}</Paragraph>
        <Spinner color={branding.primaryColor} />
      </ParagraphWrapper>
    </Slide>
  );
};

export default FullScreenLayout;
