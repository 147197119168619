import { CheckboxGroupContainer, CheckboxItem } from "./styles";
import myNotaThumbIcon from "../../media/my-nota-thumbprint.svg";
import churchIcon from "../../media/church-icon.svg";
import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import { useTranslations } from "hooks/useTranslations";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

const icons = {
  joinNota: myNotaThumbIcon,
  lookingCommunity: churchIcon,
};
type OptionTypes = keyof typeof icons;

const IconCheckboxGroup: React.FC<LibraryComponentProps> = ({
  config,
  slugKey,
  currentAnswer,
  onAnswerChange,
}) => {
  const { t } = useTranslations();
  const { branding } = useSpiritualAssessmentContext();

  const { options, variable } = config;
  const toggleCheckbox: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    if (target.checked) {
      onAnswerChange([...(currentAnswer ?? []), target.value]);
    } else {
      const newitemsChecked = [...(currentAnswer ?? [])].filter(
        (x) => x !== target.value
      );
      onAnswerChange(newitemsChecked);
    }
  };

  return (
    <CheckboxGroupContainer>
      {options &&
        options.map((option: string, i: number) => (
          <CheckboxItem
            key={i}
            primaryColor={branding.primaryColor}
            primaryColorLight="#F0F5FB"
          >
            <div className="checkbox-icon">
              <img
                src={icons[option as OptionTypes]}
                alt={variable + "image"}
              />
            </div>
            <p>{t(`${slugKey}.${option}`)}</p>
            <input
              value={option}
              onChange={toggleCheckbox}
              type="checkbox"
              id={"checkbox-icon-" + i}
            />
            {/* <label htmlFor={"checkbox-icon-" + i}>{'0'}</label> */}
          </CheckboxItem>
        ))}
    </CheckboxGroupContainer>
  );
};

export default IconCheckboxGroup;
