import styled from "styled-components";

export const Animation = styled.div`
  width: 50px;
  height: 50px;

  .lds-roller {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .lds-roller div {
    animation: lds-roller 1.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 25px 25px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: ${(props) => props.color};
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: 0.2s;
  }
  .lds-roller div:nth-child(1):after {
    top: 25px;
    left: 45px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: 0.21s;
  }
  .lds-roller div:nth-child(2):after {
    top: 39px;
    left: 39px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: 0.16s;
  }
  .lds-roller div:nth-child(3):after {
    top: 45px;
    left: 25px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: 0.11s;
  }
  .lds-roller div:nth-child(4):after {
    top: 39px;
    left: 11px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: 0.07s;
  }
  .lds-roller div:nth-child(5):after {
    top: 25px;
    left: 5px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: 0.04s;
  }
  .lds-roller div:nth-child(6):after {
    top: 11px;
    left: 11px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: 0.02s;
  }
  .lds-roller div:nth-child(7):after {
    top: 5px;
    left: 25px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
