import { api } from "api";
import { useEffect } from "react";
import {
  ExecuteCustomActionDto,
  ExecuteEgressActionDto,
  ExecuteIngressActionDto,
} from "types/actions";
import { SpiritualAssessmentNavigationHookResult } from "./useSpiritualAssessmentNavigation";

export type SpiritualAssessmentActionsHookResult = {
  executeCustomAction: (action: string, data: any) => Promise<boolean>;
};
export type SpiritualAssessmentActionsHook = (
  seekerUuid: string | undefined,
  navigation: SpiritualAssessmentNavigationHookResult
) => SpiritualAssessmentActionsHookResult;

export const useSpiritualAssessmentActions: SpiritualAssessmentActionsHook = (
  seekerUuid,
  { currentStep, currentResource }
) => {
  const executeIngressActions = async (actions: string[]) => {
    if (seekerUuid) {
      await api.post<ExecuteIngressActionDto, void>("/actions/ingress", {
        actions,
        seekerUuid,
      });
    }
  };

  const executeEgressActions = async (actions: string[]) => {
    if (seekerUuid) {
      await api.post<ExecuteEgressActionDto, void>("/actions/egress", {
        actions,
        seekerUuid,
      });
    }
  };

  const checkForActions = () => {
    if (currentStep?.actions?.ingress) {
      executeIngressActions(currentStep.actions.ingress);
    } else if (currentStep?.actions?.egress) {
      executeEgressActions(currentStep.actions.egress);
    }
  };

  useEffect(() => {
    checkForActions();
  }, [currentStep, currentResource]);

  const executeCustomAction = async (action: string, data: any) => {
    const result = await api.post<ExecuteCustomActionDto, boolean>(
      "/actions/custom",
      {
        action,
        seekerUuid,
        data,
      }
    );
    return result.ok || !!result.body;
  };

  return { executeCustomAction };
};
