import { useEffect, useState } from "react";

export type Geolocation = {
  city: string;
  country: string;
  countryCode: string;
  latitude: number;
  longitude: number;
  region: string;
  regionCode: string;
};

export type GeolocationResult = {
  success: boolean;
  geolocation: Geolocation | null;
};

export const useLocation = () => {
  const [location, setLocation] = useState<Geolocation>();

  const fetchGeolocation = async () => {
    const geolocationResponse = await fetch(
      process.env.REACT_APP_GEOLOCATION_URL!
    );
    const result: GeolocationResult = await geolocationResponse.json();
    if (result.success && result.geolocation) {
      setLocation(result.geolocation);
    }
  };

  useEffect(() => {
    fetchGeolocation();
  }, []);

  return location;
};
