import { useEffect, useMemo, useState } from "react";

export type PasswordValidation = {
  length: boolean;
  lowercase: boolean;
  uppercase: boolean;
  number: boolean;
};

export type ConfirmPasswordValidation = {
  matches: boolean;
};

export type PasswordValidationResult = {
  validation: PasswordValidation | ConfirmPasswordValidation;
  valid: boolean;
};

const buildPasswordValidationResult = (
  password: string
): PasswordValidationResult => {
  const validation = validatePassword(password);
  return {
    validation,
    valid: checkValidation(validation),
  };
};

const buildConfirmPasswordValidationResult = (
  password: string,
  confirmPassword: string
): PasswordValidationResult => {
  const validation = validateConfirmPassword(password, confirmPassword);
  return {
    validation,
    valid: checkValidation(validation),
  };
};

const validatePassword = (password: string): PasswordValidation => {
  return {
    length: password.length > 0,
    lowercase: !!password.match(/[a-z]+/g),
    uppercase: !!password.match(/[A-Z]+/g),
    number: !!password.match(/[0-9]+/g),
  };
};

const validateConfirmPassword = (
  password: string,
  confirmPassword: string
): ConfirmPasswordValidation => {
  return {
    matches: validatePassword(password) && password === confirmPassword,
  };
};

const checkValidation = (
  validation: PasswordValidation | ConfirmPasswordValidation
): boolean => Object.values(validation).every((x) => !!x);

export const usePasswordValidation = (
  password: string,
  confirmPassword: string
) => {
  const [passwordValidation, setPasswordValidation] =
    useState<PasswordValidationResult>(buildPasswordValidationResult(password));

  const [confirmPasswordValidation, setConfirmPasswordValidation] =
    useState<PasswordValidationResult>(
      buildConfirmPasswordValidationResult(password, confirmPassword)
    );

  useEffect(() => {
    setPasswordValidation(buildPasswordValidationResult(password));
    setConfirmPasswordValidation(
      buildConfirmPasswordValidationResult(password, confirmPassword)
    );
  }, [password, confirmPassword]);

  return {
    passwordValidation,
    confirmPasswordValidation,
  };
};
