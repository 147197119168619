import { useEffect, useRef, useState } from "react";
import { StyledButton, SubLabel } from "./styles";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

type ButtonProps = {
  onClick: () => void;
  label: string;
  subLabel?: string;
  hasCounter?: boolean;
  disabled?: boolean;
  time?: number;
};
const Button: React.FC<ButtonProps> = ({
  onClick,
  label,
  subLabel,
  hasCounter,
  disabled,
  time = 5,
}) => {
  //eslint-disable-next-line
  const [enable, setEnable] = useState<boolean>(!hasCounter);
  const [counter, setCounter] = useState<number>(time);
  const intervalRef = useRef<any>(null);

  const { branding } = useSpiritualAssessmentContext();

  useEffect(() => {
    if (hasCounter && !intervalRef.current) {
      intervalRef.current = setInterval(
        () => setCounter((value) => (value > 0 ? value - 1 : 0)),
        1000
      );
    }
  }, [hasCounter]);

  useEffect(() => {
    if (counter <= 0) {
      setEnable(true);
      clearInterval(intervalRef.current);
    }
  }, [counter]);

  return (
    <StyledButton
      onClick={onClick}
      primaryColor={branding.primaryColor}
      disabled={disabled}
    >
      <p>
        {label} {hasCounter && time > 0 && `(${Math.floor(time)}s)`}
      </p>
      {subLabel && disabled && <SubLabel>{subLabel}</SubLabel>}
    </StyledButton>
  );
};

export default Button;
