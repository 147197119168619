import styled from "styled-components";

export const ParagraphWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`;
export const Paragraph = styled.p`
  text-align: center;
  max-width: 42rem;
  font-weight: 700;
  font-size: 2rem;
  @media screen and (max-width: 480px) {
    text-align: left;
    font-size: 1.3rem;
  }
`;
