import styled from "styled-components";

type SlideGradientProps = {
  primaryColor: string;
  secondaryColor: string;
};

export const SlideGradient = styled.div<SlideGradientProps>`
  position: relative;
  padding: 2rem;
  min-height: 100vh;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    ${(props) => props.primaryColor + "1a"},
    ${(props) => props.secondaryColor + "1a"}
  );
  @media screen and (max-width: 1024px) {
    gap: 1rem;
    height: auto;
  }
`;

export const SlideBackground = styled.img.attrs((props) => ({
  src: props.src,
}))`
  position: absolute;
`;
