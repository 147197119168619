import { useEffect, useState } from "react";
import { SpiritualAssessment } from "types/spiritualAssessment";

export type SpiritualAssessmentTranslationMap = {
  [key: string]: string;
};

export type SpiritualAssessmentTranslationsHookResult = {
  translations: SpiritualAssessmentTranslationMap;
};
export type SpiritualAssessmentTranslationsHook = (
  assessment?: SpiritualAssessment
) => SpiritualAssessmentTranslationsHookResult;

export const useSpiritualAssessmentTranslations = (
  assessment?: SpiritualAssessment
) => {
  const [translations, setTranslations] =
    useState<SpiritualAssessmentTranslationMap>({});

  useEffect(() => {
    if (assessment) {
      const translationMap: SpiritualAssessmentTranslationMap = {};
      assessment.translations.reduce((res, { key, value }) => {
        res[key] = value;
        return res;
      }, translationMap);
      setTranslations(translationMap);
    }
  }, [assessment]);

  return { translations };
};
