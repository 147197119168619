import styled from "styled-components";

export const DatePickerContainer = styled.div`
  display: flex;
  border: 1px solid #d7d7d7;
  border-radius: 7px;
  width: 181px;
  height: 53px;

  .calendar-icon {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #d7d7d7;
  }

  select {
    appearance: none;
    flex: 1;
    border-radius: 0px 7px 7px 0px;
    border: none;
    font-size: 1rem;
    font-family: "NoirProMedium";
    text-align: center;
    cursor: pointer;

    &.greyed {
      color: #d9d9d9;
    }
  }

  @media screen and (max-width: 1024px) {
    .calendar-icon {
      width: 33.33%;
    }
  }
`;
