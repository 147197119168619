import { rootApi } from "api";
import { useEffect, useState } from "react";

export type Partner = {
  uuid: string;
  created_at: string;
  updated_at: string;
  name: string;
  short_code: string;
  url_short_code: string;
  email_base: string;
};

export const usePartner = (loadingParams: boolean, partnerCode: string) => {
  const [partner, setPartner] = useState<Partner>();

  const fetchPartner = async () => {
    const result = await rootApi.get<{ urlShortCode: string }, Partner>(
      "partner",
      { urlShortCode: partnerCode! }
    );
    if (result.ok) {
      setPartner(result.body);
    }
  };

  useEffect(() => {
    if (!loadingParams && partnerCode) {
      console.log(partnerCode);
      fetchPartner();
    }
  }, [loadingParams, partnerCode]);

  return partner;
};
