import { PropsWithChildren } from "react";
import { TooltipContent, TooltipWrapper } from "./styles";

export interface TooltipProps {
  placement: "right" | "left" | "top" | "bottom";
  actionArea: any;
  title?: string;
  content?: any;
}

const Tooltip = ({
  placement = "right",
  actionArea,
  children,
  title,
  content,
}: PropsWithChildren<TooltipProps>) => {
  return (
    <TooltipWrapper>
      {actionArea}
      <TooltipContent className={placement}>
        <i></i>
        {title && <h5>{title}</h5>}
        {content ? content : children}
      </TooltipContent>
    </TooltipWrapper>
  );
};

export default Tooltip;
