import styled from "styled-components";

export const PasswordValidationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PasswordValidationRowWrapper = styled.div`
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 5px 0;
`;

type PasswordValidationIconProps = {
  valid?: boolean;
};
export const PasswordValidationIcon = styled.div<PasswordValidationIconProps>`
  background: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Transparent by default */
  background: transparent;
  border: 1px solid #ff4b45;
  color: #ff4b45;

  ${({ valid }: PasswordValidationIconProps) =>
    valid &&
    `
        content: "";
        color: transparent;
        border: none;
        border-color: #5ABF7E;
        background: #5ABF7E;
        background-image: url("data:image/svg+xml,%3Csvg width='23' height='16' viewBox='0 0 23 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.5312 0.96875C22.1406 1.53125 22.1406 2.51562 21.5312 3.07812L9.53125 15.0781C8.96875 15.6875 7.98438 15.6875 7.42188 15.0781L1.42188 9.07812C0.8125 8.51562 0.8125 7.53125 1.42188 6.96875C1.98438 6.35938 2.96875 6.35938 3.53125 6.96875L8.45312 11.8906L19.4219 0.96875C19.9844 0.359375 20.9688 0.359375 21.5312 0.96875Z' fill='white'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;
    `};
`;
