import { SendButton, SendSuccessText, Wrapper } from "./styles";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import { useTranslations } from "hooks/useTranslations";
import { useInteractionTrackingContext } from "hooks/context/interactionTracking/interactionTrackingContext";
import { useSpiritualAssessmentContext } from "hooks/context/spiritualAssessment/spiritualAssessmentContext";
import { useState } from "react";

const InputPhone: React.FC<LibraryComponentProps> = ({
  slugKey,
  currentAnswer,
  onAnswerChange,
  config,
}) => {
  const { location } = useInteractionTrackingContext();
  const [actionSuccess, setActionSuccess] = useState(false);
  const { executeCustomAction } = useSpiritualAssessmentContext();
  const { t } = useTranslations();
  const { branding } = useSpiritualAssessmentContext();

  const onAction = async () => {
    const result = await executeCustomAction(config.action!, {
      [config.attributeKey!]: currentAnswer,
    });
    setActionSuccess(result);
  };

  return (
    <Wrapper>
      <PhoneInput
        value={currentAnswer}
        onChange={onAnswerChange}
        defaultCountry={location?.countryCode as any}
      />
      {!!config.action && !actionSuccess && (
        <SendButton onClick={onAction} color={branding.primaryColor}>
          {t(`${slugKey}.send`)}
        </SendButton>
      )}
      {!!config.action && actionSuccess && (
        <SendSuccessText>Sent!</SendSuccessText>
      )}
    </Wrapper>
  );
};
export default InputPhone;
