import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fcfeff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 34px 7px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  margin: 2rem 7rem;
  width: 1175px;
  display: flex;
  flex: 1;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    margin: 0;
    width: 100%;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
  @media screen and (max-width: 1024px) {
    padding: 1rem 2rem;
  }
`;

export const Media = styled.div`
  display: flex;
  flex: 0 0 40%;
  flex-direction: row-reverse;

  * {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    /* max-height: 250px; */
    flex-direction: row;
    justify-content: center;
    * {
      border-radius: 12px 12px 0 0;
    }
  }
`;

export const Header = styled.div`
  margin-bottom: 40px;
`;

export const Body = styled.div({});

export const Footer = styled.div`
  margin-top: auto;
`;
