import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useInteractionTrackingContext } from "../interactionTracking/interactionTrackingContext";
import {
  SpiritualAssessmentActionsHookResult,
  useSpiritualAssessmentActions,
} from "./useSpiritualAssessmentActions";
import {
  SpiritualAssessmentAnswersHookResult,
  useSpiritualAssessmentAnswers,
} from "./useSpiritualAssessmentAnswers";
import {
  SpiritualAssessmentFetchHookResult,
  useSpiritualAssessmentFetch,
} from "./useSpiritualAssessmentFetch";
import { useSpiritualAssessmentInteractions } from "./useSpiritualAssessmentInteractions";
import {
  SpiritualAssessmentNavigationHookResult,
  useSpiritualAssessmentNavigation,
} from "./useSpiritualAssessmentNavigation";
import {
  SpiritualAssessmentSubmitHookResult,
  useSpiritualAssessmentSubmit,
} from "./useSpiritualAssessmentSubmit";
import {
  SpiritualAssessmentTranslationsHookResult,
  useSpiritualAssessmentTranslations,
} from "./useSpiritualAssessmentTranslations";

export type BrandingPackageType = {
  logo_url: string;
  primary_color_index: number;
  primary_colors: string[];
  secondary_color_index: number;
  secondary_colors: string[];
  accent_color_index: number;
  accent_colors: string[];
  primaryColor: string;
  secondaryColor: string;
  accentColor: string;
};

export type SpiritualAssessmentContextResult =
  SpiritualAssessmentFetchHookResult &
    SpiritualAssessmentNavigationHookResult &
    SpiritualAssessmentAnswersHookResult &
    SpiritualAssessmentSubmitHookResult &
    SpiritualAssessmentTranslationsHookResult &
    SpiritualAssessmentActionsHookResult & { branding: BrandingPackageType } & {
      setParams: React.Dispatch<{ partner?: string; language?: string }>;
    };

const spiritualAssessmentContext =
  createContext<SpiritualAssessmentContextResult>({} as any);

export const SpiritualAssessmentContext = (props: PropsWithChildren<{}>) => (
  <spiritualAssessmentContext.Provider value={useSpiritualAssessment()}>
    {props.children}
  </spiritualAssessmentContext.Provider>
);

export const useSpiritualAssessmentContext = () =>
  useContext(spiritualAssessmentContext);

const useSpiritualAssessment = (): SpiritualAssessmentContextResult => {
  const [seekerUuid, setSeekerUuid] = useState<string>();

  const { partnerCode, language, setParams } = useInteractionTrackingContext();
  const { assessment } = useSpiritualAssessmentFetch(partnerCode, language);
  const navigation = useSpiritualAssessmentNavigation(assessment!);
  const answers = useSpiritualAssessmentAnswers(navigation.rawNavigationState);
  const { submitSpiritualAssessment } = useSpiritualAssessmentSubmit(
    assessment!,
    answers.answers,
    seekerUuid,
    setSeekerUuid
  );
  const { translations } = useSpiritualAssessmentTranslations(assessment);
  const { executeCustomAction } = useSpiritualAssessmentActions(
    seekerUuid,
    navigation
  );

  useSpiritualAssessmentInteractions(
    navigation.currentStep,
    navigation.currentResource,
    answers.currentAnswerMap
  );

  const branding = {
    ...assessment?.branding_package,
    primaryColor:
      assessment?.branding_package?.primary_colors[
        assessment?.branding_package?.primary_color_index
      ] ?? "#094D92",
    secondaryColor:
      assessment?.branding_package?.secondary_colors[
        assessment?.branding_package?.secondary_color_index
      ],
    accentColor:
      assessment?.branding_package?.accent_colors[
        assessment?.branding_package?.accent_color_index
      ] ?? "#EE4B6A",
  };

  return {
    assessment,
    branding,
    ...navigation,
    ...answers,
    submitSpiritualAssessment,
    translations,
    executeCustomAction,
    setParams,
  };
};
