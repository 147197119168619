import { Animation } from "./styles";

type SpinnerProps = {
  color: string;
};

const Spinner: React.FC<SpinnerProps> = ({ color }) => {
  return (
    <Animation color={color}>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Animation>
  );
};

export default Spinner;
