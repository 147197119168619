import { PasswordValidationIcon, PasswordValidationRowWrapper } from "./styles";

export type PasswordValidationRowProps = {
  valid: boolean;
  label: string;
};

export const PasswordValidationRow = ({
  valid,
  label,
}: PasswordValidationRowProps) => {
  return (
    <PasswordValidationRowWrapper>
      <PasswordValidationIcon valid={valid}>
        {valid ? null : "X"}
      </PasswordValidationIcon>
      <span> {label}</span>
    </PasswordValidationRowWrapper>
  );
};
