import { api } from "api";
import { useResults } from "hooks/useResults";
import {
  SpiritualAssessment,
  SpiritualAssessmentResourceData,
} from "types/spiritualAssessment";
import {
  SpiritualAssessmentSeekerDto,
  SpiritualAssessmentSubmissionDto,
  SpiritualAssessmentUserDto,
} from "types/spiritualAssessmentSubmission";
import { useInteractionTrackingContext } from "../interactionTracking/interactionTrackingContext";
import {
  SpiritualAssessmentAnswer,
  SpiritualAssessmentAnswers,
} from "./useSpiritualAssessmentAnswers";

export type SpiritualAssessmentSubmitHookResult = {
  submitSpiritualAssessment: () => Promise<void>;
};
export type SpiritualAssessmentSubmitHook = (
  assessment: SpiritualAssessment,
  answers: SpiritualAssessmentAnswers,
  seekerUuid: string | undefined,
  setSeekerUuid: (uuid: string) => void
) => SpiritualAssessmentSubmitHookResult;

export const useSpiritualAssessmentSubmit: SpiritualAssessmentSubmitHook = (
  assessment,
  answers,
  seekerUuid,
  setSeekerUuid
) => {
  const { location } = useInteractionTrackingContext();
  const getResults = useResults(assessment, answers);

  const addAnswerToSubmission = (
    answer: SpiritualAssessmentAnswer,
    answerSlugKey: string,
    associatedData: SpiritualAssessmentResourceData,
    submission: SpiritualAssessmentSubmissionDto
  ) => {
    switch (associatedData.attributeType) {
      case "seeker":
        submission.seeker = {
          ...submission.seeker,
          [associatedData.attributeKey as keyof SpiritualAssessmentSeekerDto]:
            answer,
        };
        return;
      case "user":
        submission.user = {
          ...submission.user,
          [associatedData.attributeKey as keyof SpiritualAssessmentUserDto]:
            answer,
        };
        return;
      case "interaction":
        submission.interaction.push({
          interaction_platform: "spiritual_assessment",
          interaction_type: "question",
          data: { key: answerSlugKey, answer },
        });
        return;
      case "meta":
        submission.meta.push({
          meta_key: associatedData.attributeKey ?? associatedData.variable,
          meta_value: String(answer),
        });
        return;
      case "impactMetric":
        const answerArr = answer as string[];
        submission.impactMetric.push(
          ...answerArr.map((x) => ({
            metric_name: x,
            metric_type: "manual" as "manual",
          }))
        );
    }
  };

  const buildSpiritualAssessmentSubmission =
    (): SpiritualAssessmentSubmissionDto => {
      const result: SpiritualAssessmentSubmissionDto = {
        seeker: {
          uuid: seekerUuid,
        },
        user: {
          uuid: seekerUuid,
          coords: !!location ? [location.latitude, location.longitude] : null,
        },
        interaction: [
          {
            interaction_platform: "spiritual_assessment",
            interaction_type: "result",
            data: getResults(),
          },
        ],
        meta: [],
        impactMetric: [],
      };

      for (const stepIndex in answers) {
        const associatedStep = assessment.steps[stepIndex];
        for (const resourceIndex in answers[stepIndex]) {
          const associatedResource = associatedStep.resources[resourceIndex];
          for (const key in answers[stepIndex][resourceIndex]) {
            const associatedResourceData = associatedResource.data.find(
              (x) => x.variable === key
            )!;
            const answerSlugKey = `${associatedStep.slug}.${associatedResource.slug}.${associatedResourceData.variable}`;
            addAnswerToSubmission(
              answers[stepIndex][resourceIndex][key],
              answerSlugKey,
              associatedResourceData,
              result
            );
          }
        }
      }

      return result;
    };
  const submitSpiritualAssessment = async () => {
    const submission = buildSpiritualAssessmentSubmission();
    console.log(submission);
    const result = await api.post<
      SpiritualAssessmentSubmissionDto,
      { uuid: string }
    >("actions/submit", submission);
    if (result.ok) {
      setSeekerUuid(result.body.uuid);
    }
  };

  return {
    submitSpiritualAssessment,
  };
};
