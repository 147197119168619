import styled from "styled-components";
const iconHeigth = 16;
const iconWidth = 35;
export const Wrapper = styled.div`
  /* width: 100%; */
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .PhoneInput {
    position: relative;
    width: 100%;
  }
  .PhoneInputInput {
    font-family: inherit;
    box-sizing: border-box;
    height: 45px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    outline: none;
    padding: 0 20px 0 ${iconWidth + 15}px;
  }
  .PhoneInputCountry {
    width: ${iconWidth}px;
    position: absolute;
    top: calc(50% - ${iconHeigth / 2}px);
    color: #094d92;
    left: 10px;
  }
`;

type SendButtonProps = { color: string };
export const SendButton = styled.button<SendButtonProps>`
  background: transparent;
  font-family: "NoirProBold";
  font-size: 1rem;
  color: ${(props) => props.color};
  border: 1px solid;
  border-color: ${(props) => props.color};
  padding: 10px 0;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
`;
export const SendSuccessText = styled.p`
  width: 100%;
  text-align: center;
`;
