import { useState } from "react";
import { SpiritualAssessment } from "types/spiritualAssessment";
import { SpiritualAssessmentAnswers } from "./context/spiritualAssessment/useSpiritualAssessmentAnswers";

export const useResults = (
  assessment: SpiritualAssessment,
  answers: SpiritualAssessmentAnswers,
  options?: string[]
) => {
  const [results, setResults] = useState<
    { [key: string]: number } | undefined
  >();

  const stepsInResult =
    options ??
    assessment?.steps
      ?.filter((step) => step.slug === "results")?.[0]
      .resources.filter((resource) => resource.slug === "yourResults")?.[0]
      .data.filter((data) => data.variable === "result")?.[0].options;

  const keyStepsMap = assessment?.steps.reduce((acc: any, step, key) => {
    if (stepsInResult?.includes(step.slug)) acc[step.slug] = key;
    return acc;
  }, {});

  const questionsGuide = assessment?.steps?.reduce(
    (acc: any, step, keyStep) => {
      const { resources } = step;
      if (stepsInResult?.includes(step.slug)) {
        const resourceMap = resources.reduce(
          (accum: any, resource, keyResource) => {
            const data = resource.data
              .filter((d) => d?.options)
              .map((dataEntry: any) => {
                return { [dataEntry.variable]: dataEntry.options };
              });
            if (data.length) accum[keyResource] = data[0];
            return accum;
          },
          {}
        );
        acc[keyStep] = { ...resourceMap };
      }
      return acc;
    },
    {}
  );

  function calculateScore(answerIndex: number, optionsPerView: number[]) {
    return (answerIndex / (optionsPerView.length - 1)) * 100;
  }

  function calculateScorePerView(step: number, views: any) {
    return views.map((view: any) => {
      /**
       * TODO When need to use multiple scoreable questions inside the same view
       * we need to improve this fuction in order to support them.
       * */
      const attribute = Object.keys(questionsGuide[keyStepsMap[step]][view])[0];
      const optionsPerView = questionsGuide[keyStepsMap[step]][view];
      const answer = answers[keyStepsMap[step]]?.[view]?.[attribute];
      const answerIndex = optionsPerView[attribute].indexOf(answer);
      const viewScore = calculateScore(answerIndex, optionsPerView[attribute]);
      return viewScore;
    });
  }

  function calculateStepScore(scorePerView: number[]) {
    return Math.round(
      scorePerView.reduce((acc, score) => {
        return (acc += score);
      }) / scorePerView.length
    );
  }

  function calculateTotalScores() {
    if (results) {
      return results;
    } else {
      const result = stepsInResult?.reduce((acc: any, step: any) => {
        const views = Object.keys(questionsGuide[keyStepsMap[step]]);
        const scorePerView = calculateScorePerView(step, views);
        const stepScore = calculateStepScore(scorePerView);
        acc[step] = acc[step] ? acc[step].push(stepScore) : stepScore;
        return acc;
      }, {});

      setResults(result);
      return result;
    }
  }

  return calculateTotalScores;
};
