import styled from 'styled-components';

export const IconRadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const RadioOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  border: solid 1px #d9d9d9;
  padding: 15px 30px;
  border-radius: 12px;
  align-items: center;
  text-align: center;
  background: #fcfeff;
  width: 132px;
  /* height: 126px; */
`;

type InputProps = {
  color: string;
};

export const Input = styled.input<InputProps>`
  width: 20px;
  height: 20px;
  visibility: hidden;
  :after {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: -1px;
    left: 0;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #d7d7d7;
  }

  :checked::after {
    background-color: ${(props) => props.color};
  }
`;
