import { api } from "api";
import { useEffect, useState } from "react";
import { SpiritualAssessment } from "types/spiritualAssessment";

export type SpiritualAssessmentFetchHookResult = {
  assessment: SpiritualAssessment | undefined;
};
export type SpiritualAssessmentFetchHook = (
  partnerCode?: string,
  language?: string
) => SpiritualAssessmentFetchHookResult;

export const useSpiritualAssessmentFetch: SpiritualAssessmentFetchHook = (
  partnerCode,
  language
) => {
  const [assessment, setAssessment] = useState<SpiritualAssessment>();

  const fetchSpiritualAssessment = async () => {
    const result = await api.get<any, SpiritualAssessment>(
      `${partnerCode}/${language}`
    );
    if (result.ok) {
      setAssessment(result.body);
    } else {
      throw new Error("Unable to load spiritual assessment");
    }
  };

  useEffect(() => {
    if (partnerCode && language) {
      fetchSpiritualAssessment();
    }
  }, [partnerCode, language]);

  return { assessment };
};
