import { HeaderSection, Subtitle, Title } from "./styles";

type PageHeaderProps = {
  title: string;
  subtitle?: string;
};

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle }) => {
  return (
    <HeaderSection>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </HeaderSection>
  );
};

export default PageHeader;
