import { useEffect, useState } from "react";
import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import { RadioSliderContainer, MobileRadioSlider } from "./styles";
import { useTranslations } from "hooks/useTranslations";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

const RadioSlider: React.FC<LibraryComponentProps> = ({
  config,
  slugKey,
  currentAnswer,
  onAnswerChange,
}) => {
  const { t } = useTranslations();
  const { branding } = useSpiritualAssessmentContext();

  const { options } = config;

  const [valueChecked, setValueChecked] = useState(0);
  const [sliderTrackWidth, setSliderTrackWidth] = useState("0.00%");

  useEffect(() => {
    onAnswerChange(options![valueChecked]);
  }, [valueChecked]);

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setValueChecked(Number(newValue));

    const newSliderWidthPercentage = calculateSliderTrackWidth(
      Number(newValue)
    );
    setSliderTrackWidth(newSliderWidthPercentage);
  };

  const calculateSliderTrackWidth = (newValue: number) => {
    const numDivisions = options!.length - 1;
    const percentage = (newValue / numDivisions) * 100;
    return percentage.toString() + "%";
  };

  const isDesktop = window.innerWidth > 1024;

  return (
    <RadioSliderContainer
      primaryColor={branding.primaryColor}
      sliderTrackWidth={sliderTrackWidth}
    >
      {isDesktop ? (
        <div className="range-container">
          <div className="range">
            <input
              type="range"
              id="options"
              name="options"
              min="0"
              max={options!.length - 1}
              step="1"
              value={valueChecked}
              onChange={handleInputChange}
            />
          </div>
          <ul className="range-labels">
            {options &&
              options.map((option: string, index: number) => (
                <li
                  key={index}
                  className={`
                            ${index === valueChecked && "active"}
                            ${index < valueChecked && "filled"}
                            `}
                >
                  <span>{t(`${slugKey}.${option}`)}</span>
                </li>
              ))}
          </ul>
        </div>
      ) : (
        <MobileRadioSlider primaryColor={branding.primaryColor}>
          <div className="mobile-radio-nav">
            <button
              className="prev"
              disabled={valueChecked === 0}
              onClick={() => setValueChecked(valueChecked - 1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="27"
                viewBox="0 0 16 27"
                fill="none"
              >
                <path
                  d="M13.375 0.125C13.9023 0.125 14.3711 0.300781 14.7227 0.652343C15.4844 1.35547 15.4844 2.58594 14.7227 3.28906L4.82031 13.25L14.7227 23.1523C15.4844 23.8555 15.4844 25.0859 14.7227 25.7891C14.0195 26.5508 12.7891 26.5508 12.0859 25.7891L0.835939 14.5391C0.07422 13.8359 0.0742201 12.6055 0.835939 11.9023L12.0859 0.652343C12.4375 0.300781 12.9063 0.124999 13.375 0.125Z"
                  fill={branding.primaryColor}
                />
              </svg>
            </button>
            <div className="mobile-nav-label">
              {t(`${slugKey}.${options![valueChecked]}`)}
            </div>
            <button
              className="next"
              disabled={valueChecked === options!.length - 1}
              onClick={() => setValueChecked(valueChecked + 1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="27"
                viewBox="0 0 16 27"
                fill="none"
              >
                <path
                  d="M13.375 0.125C13.9023 0.125 14.3711 0.300781 14.7227 0.652343C15.4844 1.35547 15.4844 2.58594 14.7227 3.28906L4.82031 13.25L14.7227 23.1523C15.4844 23.8555 15.4844 25.0859 14.7227 25.7891C14.0195 26.5508 12.7891 26.5508 12.0859 25.7891L0.835939 14.5391C0.07422 13.8359 0.0742201 12.6055 0.835939 11.9023L12.0859 0.652343C12.4375 0.300781 12.9063 0.124999 13.375 0.125Z"
                  fill={branding.primaryColor}
                />
              </svg>
            </button>
          </div>
          <div className="mobile-radio-inputs">
            {options &&
              options.map((option: string, index: number) => (
                <div key={index}>
                  <label htmlFor={`${slugKey}.${option}`}>{option}</label>
                  <input
                    id={`${slugKey}.${option}`}
                    name={`${slugKey}.${option}`}
                    key={index}
                    type="radio"
                    checked={index === valueChecked}
                    value={index}
                    onChange={handleInputChange}
                  />
                </div>
              ))}
          </div>
        </MobileRadioSlider>
      )}
    </RadioSliderContainer>
  );
};

export default RadioSlider;
