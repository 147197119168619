import Slide from "../../library/slide";
import StepsBar from "../../library/stepsBar";
import Card from "../../library/card";
import PageHeader from "../../library/pageHeader";
import FormItem from "../../library/formItem";
import Button from "../../library/button";

import { LayoutProps } from "components/layout/layout";
import LibraryComponent from "components/libraryComponent/libraryComponent";
import { useTranslations } from "hooks/useTranslations";
import { useSpiritualAssessmentContext } from "hooks/context/spiritualAssessment/spiritualAssessmentContext";
import { useState } from "react";
import { SpiritualAssessmentAnswer } from "hooks/context/spiritualAssessment/useSpiritualAssessmentAnswers";

const DefaultLayout: React.FC<LayoutProps> = (props) => {
  const slugKey = props.slugs.join(".");
  const { t } = useTranslations();
  const { getAnswerByKey } = useSpiritualAssessmentContext();
  const [name] = useState(getAnswerByKey("firstName"));
  const validateAnswer = (answer: SpiritualAssessmentAnswer) => {
    switch (typeof answer) {
      case "object":
        return answer.length > 0;
      case "string":
        return !!answer.trim();
      default:
        return !!answer;
    }
  };
  const isDisable = () => {
    let allConfigDataIsValid = props.config.every(({ variable, require }) => {
      const answer = props.currentAnswerMap?.[variable!];
      if (require === false) {
        return true;
      } else {
        return answer ? validateAnswer(answer) : false;
      }
    });
    return !allConfigDataIsValid;
  };

  return (
    <Slide>
      <StepsBar stepIndex={props.step} steps={props.steps} />
      <Card>
        <Card.Content>
          <Card.Header>
            <PageHeader
              title={t(`${slugKey}.title`, { name: (name as any) ?? "" })}
              subtitle={t(`${slugKey}.text`)}
            />
          </Card.Header>
          <Card.Body>
            {props.config.map((item) => {
              const componentSlugKey = `${slugKey}.${item.variable}`;
              return (
                <FormItem
                  key={componentSlugKey}
                  label={t(componentSlugKey)}
                  withoutLabel={item.withoutLabel}
                >
                  <LibraryComponent
                    config={item}
                    slugKey={componentSlugKey}
                    currentAnswer={props.currentAnswerMap?.[item.variable]}
                    onAnswerChange={(answer: any) =>
                      props.onAnswerChange(item.variable, answer)
                    }
                  />
                </FormItem>
              );
            })}
          </Card.Body>
          <Card.Footer>
            {!props.lastResource && (
              <Button
                disabled={isDisable()}
                onClick={props.onNextClick}
                label={t(`${slugKey}.button`)}
                subLabel={t(`${slugKey}.button.sub`)}
              ></Button>
            )}
          </Card.Footer>
        </Card.Content>
      </Card>
    </Slide>
  );
};

export default DefaultLayout;
