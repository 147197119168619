import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import PasswordValidation from "components/passwordValidation";
import { usePasswordValidation } from "hooks/usePasswordValidation";
import { useTranslations } from "hooks/useTranslations";
import FormItem from "library/formItem";
import Tooltip from "library/tooltip/tooltip";
import { useEffect, useState } from "react";
import { Input } from "./styles";

const InputText: React.FC<LibraryComponentProps> = ({
  slugKey,
  currentAnswer,
  onAnswerChange,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { passwordValidation, confirmPasswordValidation } =
    usePasswordValidation(password, confirmPassword);

  useEffect(() => {
    if (passwordValidation.valid && confirmPasswordValidation.valid) {
      onAnswerChange(password);
    } else if (currentAnswer) {
      onAnswerChange(undefined);
    }
  }, [passwordValidation, confirmPasswordValidation]);

  const { t } = useTranslations();
  return (
    <>
      <Tooltip
        placement="top"
        actionArea={
          <Input
            placeholder={t(slugKey)}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={"password"}
          />
        }
      >
        <PasswordValidation
          validation={passwordValidation.validation}
          slugKey={`${slugKey}.validation`}
        />
      </Tooltip>
      <div style={{ height: 20 }} />
      <FormItem label={t(`${slugKey}.confirm`)}>
        <Tooltip
          placement="top"
          actionArea={
            <Input
              placeholder={t(`${slugKey}.confirm`)}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={"password"}
            />
          }
        >
          <PasswordValidation
            validation={confirmPasswordValidation.validation}
            slugKey={`${slugKey}.validation`}
          />
        </Tooltip>
      </FormItem>
    </>
  );
};
export default InputText;
