import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import { useSpiritualAssessmentContext } from "hooks/context/spiritualAssessment/spiritualAssessmentContext";
import { useTranslations } from "hooks/useTranslations";
import { Input, RadioButtonWrapper, Label } from "./styles";

const RadioButton: React.FC<LibraryComponentProps> = ({
  slugKey,
  config,
  currentAnswer,
  onAnswerChange,
}) => {
  const { t } = useTranslations();
  const { branding } = useSpiritualAssessmentContext();
  const { options, direction } = config;
  return (
    <RadioButtonWrapper direction={direction}>
      {options &&
        options.map((option: string, i: number) => (
          <Label key={i}>
            <Input
              primaryColor={branding.primaryColor}
              value={option}
              type="radio"
              checked={currentAnswer === option}
              onChange={(e) => {
                onAnswerChange(e.target.value);
              }}
            />
            <p>{t(`${slugKey}.${option}`)}</p>
          </Label>
        ))}
    </RadioButtonWrapper>
  );
};

export default RadioButton;
