import DefaultLayout from "../../layouts/default";
import MediaLayout from "../../layouts/media";
import FullScreenLayout from "../../layouts/fullscreen";
import {
  SpiritualAssessmentResourceData,
  SpiritualAssessmentStep,
  SpiritualAssessmentStepResourceLayout,
} from "types/spiritualAssessment";
import { SpiritualAssessmentAnswer } from "hooks/context/spiritualAssessment/useSpiritualAssessmentAnswers";

export type LayoutProps = {
  layout: SpiritualAssessmentStepResourceLayout;
  step: number;
  slugs: string[];
  steps: SpiritualAssessmentStep[];
  config: SpiritualAssessmentResourceData[];
  onNextClick: () => void;
  onBackClick: () => void;
  currentAnswerMap: { [key: string]: SpiritualAssessmentAnswer };
  onAnswerChange: (key: string, answer: any) => void;
  lastResource: boolean;
};

const Layout: React.FC<LayoutProps> = (props) => {
  switch (props.layout) {
    case "default":
    case "results":
      return <DefaultLayout {...props} />;
    case "video":
    case "image":
      return <MediaLayout {...props} />;
    case "fullscreen":
      return <FullScreenLayout {...props} />;
    default:
      return <DefaultLayout {...props} />;
  }
};

export default Layout;
