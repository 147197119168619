import { useState } from "react";
import { useSpiritualAssessmentContext } from "./context/spiritualAssessment/spiritualAssessmentContext";

export type SpiritualAssessmentTranslationMap = {
  [key: string]: string;
};

export const useTranslations = () => {
  const { translations } = useSpiritualAssessmentContext();

  const [translationCache, setTranslationCache] =
    useState<SpiritualAssessmentTranslationMap>({});

  const t = (key: string, templateValues?: { [key: string]: string }) => {
    if (translationCache[key]) {
      return translationCache[key];
    }

    let translation = translations[key];
    if (!translation) {
      return key;
    }

    const templateStringMatch = new RegExp("%%(.*)%%", "gm");
    translation = translation.replace(
      templateStringMatch,
      (matched, key, i, j, k) => {
        return templateValues?.[key] ?? matched;
      }
    );

    const result = translation ?? key;
    setTranslationCache({
      ...translationCache,
      [key]: result,
    });
    return result;
  };

  return { t };
};
