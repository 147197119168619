import {
  PasswordValidation as PasswordValidationResult,
  ConfirmPasswordValidation as ConfirmPasswordValidationResult,
} from "hooks/usePasswordValidation";
import { useTranslations } from "hooks/useTranslations";
import { PasswordValidationRow } from "./passwordValidationRow";
import { PasswordValidationWrapper } from "./styles";

export type PasswordValidationProps = {
  validation: PasswordValidationResult | ConfirmPasswordValidationResult;
  slugKey: string;
};

export const PasswordValidation = ({
  validation,
  slugKey,
}: PasswordValidationProps) => {
  const { t } = useTranslations();
  return (
    <PasswordValidationWrapper>
      {Object.entries(validation).map(([key, valid]) => (
        <PasswordValidationRow
          key={key}
          valid={valid}
          label={t(`${slugKey}.${key}`)}
        />
      ))}
    </PasswordValidationWrapper>
  );
};
