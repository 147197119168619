import { useTranslations } from "hooks/useTranslations";
import { RadioProps } from "library/comboRadio/comboRadio";
import { Input, Label, RadioButtonWrapper } from "./styles";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

const RadioInput: React.FC<RadioProps> = (props) => {
  const { t } = useTranslations();
  const { branding } = useSpiritualAssessmentContext();

  const { slugKey, config } = props;
  const { options, direction } = config;
  // const [valueChecked, setValueChecked] = useState("");
  return (
    <RadioButtonWrapper direction={direction}>
      {options &&
        options.map((option, i) => (
          <Label key={i}>
            <Input
              primaryColor={branding.primaryColor}
              value={i}
              type="radio"
              checked={options[props.valueChecked] === option}
              onChange={(e) => {
                props.setValueChecked(Number(e.target.value));
              }}
            />
            <p>{t(`${slugKey}.${option}`)}</p>
          </Label>
        ))}
    </RadioButtonWrapper>
  );
};

export default RadioInput;
