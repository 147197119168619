import styled from "styled-components";

export const CircularProgressbarList = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const CircularProgressbarItem = styled.div`
  * {
    margin: 0;
  }
  width: 8rem;
  margin: 0 auto;
`;
export const Percentage = styled.h1`
  font-weight: bold;
  font-size: 2rem;
`;

export const Label = styled.p`
  font-weight: lighter;
  font-size: 0.9rem;
`;
