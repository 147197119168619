import styled from "styled-components";

export const ProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;

    .progress-bar-logo {
      max-width: 164px;
      margin: 0 auto;
    }
  }
`;

type StepProps = {
  complete?: boolean;
  selected?: boolean;
};

export const Step = styled.div<StepProps>`
  display: flex;
  gap: 10px;
  align-items: center;

  opacity: 0.5;

  span {
    font-size: 1.25rem;
  }

  ${({ complete, selected }: any) =>
    (complete || selected) &&
    `
        opacity: 1;
    `}
`;

type StepCircleProps = {
  complete?: boolean;
  selected?: boolean;
  color?: string;
};

export const StepCircle = styled.div<StepCircleProps>`
  background: #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Transparent by default */
  background: transparent;
  border: 1px solid #d7d7d7;

  ${({ complete, color }: any) =>
    complete &&
    `
        content: "";
        color: transparent;
        border: none;
        background: ${color};
        background-image: url("data:image/svg+xml,%3Csvg width='23' height='16' viewBox='0 0 23 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.5312 0.96875C22.1406 1.53125 22.1406 2.51562 21.5312 3.07812L9.53125 15.0781C8.96875 15.6875 7.98438 15.6875 7.42188 15.0781L1.42188 9.07812C0.8125 8.51562 0.8125 7.53125 1.42188 6.96875C1.98438 6.35938 2.96875 6.35938 3.53125 6.96875L8.45312 11.8906L19.4219 0.96875C19.9844 0.359375 20.9688 0.359375 21.5312 0.96875Z' fill='white'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
    `}

  ${({ selected }: any) =>
    selected &&
    `
        background: #FFFFFF;
        border: none;
    `}
`;

type MobileNavigationProps = {
  color?: string;
};
export const MobileNavigation = styled.div<MobileNavigationProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4rem;
  padding: 0.5rem;
  position: relative;

  .nav-data {
    display: flex;
    flex-direction: column;
    text-align: center;
    .step-label {
      font-size: 1.87rem;
      font-weight: 600;
    }
    .pagination {
      text-transform: uppercase;
      color: ${(props) => (props.color ? props.color : "#094D92")};
      font-size: 0.87rem;
      font-weight: 700;
    }
  }

  button {
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: transparent;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.prev {
      left: 1%;
    }
    &.next {
      right: 1%;
      transform: rotate(-180deg);
    }
    &:disabled {
      pointer-events: none;
      svg path {
        fill: #d7d7d7;
      }
    }
  }
`;
