import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import { useTranslations } from "hooks/useTranslations";
import { Note } from "./styles";

const NoteParagraph: React.FC<LibraryComponentProps> = ({ slugKey }) => {
  const { t } = useTranslations();

  return <Note>{t(slugKey)}</Note>;
};

export default NoteParagraph;
