import { useSpiritualAssessmentContext } from "hooks/context/spiritualAssessment/spiritualAssessmentContext";
import Spinner from "library/spinner";
import Layout from "components/layout/layout";
import Background from "library/background/background";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

function SpiritualAssessment() {
  const {
    assessment,
    branding,
    currentStep,
    currentResource,
    currentAnswerMap,
    setParams,
    setAnswer,
    navigateForward,
    navigateBackward,
    submitSpiritualAssessment,
  } = useSpiritualAssessmentContext();

  const params = useParams();

  useEffect(() => {
    setParams(params);
  }, []);

  const onNextClick = async () => {
    if (currentResource.submit_on_completion) {
      await submitSpiritualAssessment();
    }

    navigateForward();
  };

  const lastResource =
    currentStep?.order === assessment!?.steps?.length &&
    currentResource?.order === currentStep?.resources?.length;

  if (!assessment && !currentResource) {
    return (
      <div className="app-loading">
        <Spinner color={branding.primaryColor} />
      </div>
    );
  } else {
    return (
      <Background url={currentStep.background_svg}>
        <Layout
          layout={currentResource.layout}
          key={currentResource.slug}
          slugs={[currentStep.slug, currentResource.slug]}
          step={currentStep.order}
          steps={assessment!.steps}
          config={currentResource.data}
          onNextClick={onNextClick}
          onBackClick={navigateBackward}
          currentAnswerMap={currentAnswerMap}
          onAnswerChange={setAnswer}
          lastResource={lastResource}
        />
      </Background>
    );
  }
}
export default SpiritualAssessment;
