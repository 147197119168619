import styled from "styled-components";

type RadioSliderProps = {
  primaryColor?: string;
  sliderTrackWidth: string;
};
export const RadioSliderContainer = styled.div<RadioSliderProps>`
  padding-left: 3.5rem;
  @media screen and (max-width: 1024px) {
    padding-left: 0;
  }
  
  .range-container {
    margin-top: -10px;
  }

  .range {
    position: relative;
    width: 70%;
    height: 50px;
  }

  input[type="range"] {
    width: 100%;
    position: absolute;
    top: 2px;
    height: 50px;
    z-index: 2;
    -webkit-appearance: none;
    cursor: pointer;

    // Thumb
    &::-webkit-slider-thumb {
      -webkit-appearance: none; // needed again for Chrome & Safari
      width: 25px;
      height: 25px;
      margin: -8px 0 0;
      border-radius: 50%;
      background: ${(props) => props.primaryColor};
      cursor: pointer;
      border: 0;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none; // needed again for Chrome & Safari
      width: 25px;
      height: 25px;
      margin: -8px 0 0;
      border-radius: 50%;
      background: ${(props) => props.primaryColor};
      cursor: pointer;
      border: 0;
    }

    &::-ms-thumb {
      -webkit-appearance: none; // needed again for Chrome & Safari
      width: 25px;
      height: 25px;
      margin: -8px 0 0;
      border-radius: 50%;
      background: ${(props) => props.primaryColor};
      cursor: pointer;
      border: 0 !important;
    }

    // Track
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 10px;
      cursor: pointer;
      background: transparent;
      background: linear-gradient(
        to right,
        ${(props) => props.primaryColor} 0%,
        ${(props) => props.primaryColor} ${(props) => props.sliderTrackWidth},
        #fff ${(props) => props.sliderTrackWidth},
        #fff 100%
      );
      border: 1px solid #d8d8d8;
      border-radius: 17.5px;
    }

    &::-moz-range-track {
      width: 100%;
      height: 10px;
      cursor: pointer;
      background: transparent;
      border: 1px solid #d8d8d8;
      border-radius: 17.5px;
    }

    &::-ms-track {
      width: 100%;
      height: 10px;
      cursor: pointer;
      background: transparent;
      border: 1px solid #d8d8d8;
      border-radius: 17.5px;
    }

    &:focus {
      // override outline/background on focus
      background: none;
      outline: none;
    }

    &::-ms-track {
      // A little somethin' somethin' for IE
      width: 100%;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
  }

  // Labels below slider
  .range-labels {
    padding: 0;
    width: 70%;
    list-style: none;
    display: flex;
    justify-content: space-between;
    z-index: 1;

    li {
      position: relative;
      text-align: center;
      color: #b2b2b2;
      cursor: pointer;
      top: -47px;
      right: 0;
      left: 2px;
      content: "";
      width: 18px;
      height: 18px;
      background: #ffffff;
      border: 1px solid #d8d8d8;
      border-radius: 50%;
      z-index: 2;
      pointer-events: none;
      display: flex;
      justify-content: center;

      /* &::before {
                content: "";
                position: absolute;
                left: calc(-50% - 5px);
                bottom: -2rem;
            } */

      span {
        position: absolute;
        width: max-content;
        top: 2rem;
        transition: ease color 0.3s;
      }
    }

    .active {
      color: initial;
      background: transparent;
      border-color: transparent;
    }

    .filled {
      background: ${(props) => props.primaryColor};
      border-color: transparent;
    }
  }
`;

type MobileRadioSliderProps = {
  primaryColor?: string;
};
export const MobileRadioSlider = styled.div<MobileRadioSliderProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: calc(1rem - 2px);

  .mobile-radio-nav {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #d7d7d7;
    border-radius: 7px;

    button,
    .mobile-nav-label {
      padding: 1rem;
    }

    button {
      background: transparent;
      border: none;
      display: flex;
      justify-content: center;
      cursor: pointer;

      &.prev {
        border-right: solid 1px #d7d7d7;
        /* padding-right: 1.5rem; */
      }

      &.next {
        border-left: solid 1px #d7d7d7;
        /* padding-left: 1.5rem; */
        svg {
          transform: rotate(180deg);
        }
      }

      :disabled {
        pointer-events: none;
        svg path {
          fill: #d7d7d7;
        }
      }

      svg {
        width: 70%;
      }
    }

    .mobile-nav-label {
      flex: 1;
      text-align: center;
      @media screen and (max-width: 400px) {
        font-size: 0.9rem;
        padding: 1rem 0;
      }
    }
  }

  .mobile-radio-inputs {
    display: flex;
    gap: 1rem;
  }

  label {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  input[type="radio"] {
    margin: 0;
    visibility: hidden;
    cursor: pointer;
    :after {
      width: 12px;
      height: 12px;
      border-radius: 15px;
      top: 0;
      left: 0;
      position: relative;
      background-color: white;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 1px solid #d7d7d7;
    }
    :checked:after {
      background-color: ${(props) => props.primaryColor};
    }
  }
`;
