import styled from "styled-components";

type ButtonProps = { primaryColor: string };
export const StyledButton = styled.button<ButtonProps>`
  background: ${(props) => (props.disabled ? "#D7D7D7" : props.primaryColor)};
  font-family: "NoirProBold";
  font-size: 1rem;
  color: ${(props) => (props.disabled ? "#222222" : "white")};
  border: none;
  padding: 20px 0;
  max-width: 340px;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  p {
    margin: 0;
  }
`;
export const SubLabel = styled.p`
  font-family: "NoirProMedium";
  margin: 0;
`;
