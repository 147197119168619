import { useMemo, useState } from "react";

export type SpiritualAssessmentAnswer = string | number | string[];
export type SpiritualAssessmentAnswers = {
  [stepIndex: number]: {
    [resourceIndex: number]: {
      [key: string]: SpiritualAssessmentAnswer;
    };
  };
};

export type SpiritualAssessmentAnswersHookResult = {
  answers: SpiritualAssessmentAnswers;
  setAnswer: (key: string, answer: SpiritualAssessmentAnswer) => void;
  currentAnswerMap: { [key: string]: SpiritualAssessmentAnswer };
  hasValidAnswer: () => boolean;
  getAnswerByKey: (key: string) => SpiritualAssessmentAnswer | null;
};
export type SpiritualAssessmentAnswersHook = (
  rawNavigationState: [number, number]
) => SpiritualAssessmentAnswersHookResult;

export const useSpiritualAssessmentAnswers: SpiritualAssessmentAnswersHook = ([
  stepIndex,
  resourceIndex,
]) => {
  const [answers, setAnswers] = useState<SpiritualAssessmentAnswers>({});

  const setAnswer = (key: string, answer: SpiritualAssessmentAnswer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [stepIndex]: {
        ...(prevAnswers[stepIndex] ?? {}),
        [resourceIndex]: {
          ...(prevAnswers[stepIndex]?.[resourceIndex] ?? {}),
          [key]: answer,
        },
      },
    }));
  };

  const hasValidAnswer = () => {
    return !!answers[stepIndex][resourceIndex];
  };

  const getAnswerByKey = (key: string) => {
    for (const stepIndex in answers) {
      for (const resourceIndex in answers[stepIndex]) {
        for (const answerKey in answers[stepIndex][resourceIndex]) {
          if (answerKey === key) {
            return answers[stepIndex][resourceIndex][answerKey];
          }
        }
      }
    }
    return null;
  };

  return {
    answers,
    currentAnswerMap: useMemo(
      () => answers[stepIndex]?.[resourceIndex],
      [stepIndex, resourceIndex, answers]
    ),
    setAnswer,
    hasValidAnswer,
    getAnswerByKey,
  };
};
