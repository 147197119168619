import {
  ProgressBarContainer,
  Step,
  StepCircle,
  MobileNavigation,
} from "./styles";
import defaultLogo from "../../media/logo.svg";
import { useTranslations } from "hooks/useTranslations";
import { SpiritualAssessmentStep } from "types/spiritualAssessment";
// import { BrandingPackageType } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

type ProgressBarProps = {
  // branding: BrandingPackageType;
  steps: SpiritualAssessmentStep[];
  stepIndex: number;
};

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { t } = useTranslations();

  const { branding } = useSpiritualAssessmentContext();
  // const { stepIndex, branding } = props;
  const { stepIndex } = props;

  const logo = branding?.logo_url;
  const accentColor = branding?.accentColor;

  const steps = props.steps.map((step) => {
    return { order: step.order, slug: step.slug };
  });

  // const onClickPrevious = () => {
  //   console.log("prev");
  // };

  // const onClickNext = () => {
  //   console.log("next");
  // };

  const isDesktop = window.innerWidth > 1024;
  return (
    <ProgressBarContainer>
      <img src={logo ?? defaultLogo} className="progress-bar-logo" alt="logo" />
      {isDesktop ? (
        steps.map((step) => (
          <Step
            key={step.order}
            complete={step.order < stepIndex}
            selected={step.order === stepIndex}
          >
            <StepCircle
              color={accentColor}
              complete={step.order < stepIndex}
              selected={step.order === stepIndex}
            >
              {step.order}
            </StepCircle>
            <span>{t(`${step.slug}.title`)}</span>
          </Step>
        ))
      ) : (
        <MobileNavigation color={accentColor}>
          {/* <button className="prev" disabled onClick={onClickPrevious}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="27"
              viewBox="0 0 16 27"
              fill="none"
            >
              <path
                d="M13.375 0.125C13.9023 0.125 14.3711 0.300781 14.7227 0.652343C15.4844 1.35547 15.4844 2.58594 14.7227 3.28906L4.82031 13.25L14.7227 23.1523C15.4844 23.8555 15.4844 25.0859 14.7227 25.7891C14.0195 26.5508 12.7891 26.5508 12.0859 25.7891L0.835939 14.5391C0.07422 13.8359 0.0742201 12.6055 0.835939 11.9023L12.0859 0.652343C12.4375 0.300781 12.9063 0.124999 13.375 0.125Z"
                fill={primaryColor ?? defaultPrimaryColor}
              />
            </svg>
          </button> */}
          <div className="nav-data">
            <span className="step-label">
              {t(`${steps[stepIndex - 1]?.slug}.title`)}
            </span>
            <span className="pagination">{`${stepIndex} of ${steps.length}`}</span>
          </div>
          {/* <button className="next" onClick={onClickNext}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="27"
              viewBox="0 0 16 27"
              fill="none"
            >
              <path
                d="M13.375 0.125C13.9023 0.125 14.3711 0.300781 14.7227 0.652343C15.4844 1.35547 15.4844 2.58594 14.7227 3.28906L4.82031 13.25L14.7227 23.1523C15.4844 23.8555 15.4844 25.0859 14.7227 25.7891C14.0195 26.5508 12.7891 26.5508 12.0859 25.7891L0.835939 14.5391C0.07422 13.8359 0.0742201 12.6055 0.835939 11.9023L12.0859 0.652343C12.4375 0.300781 12.9063 0.124999 13.375 0.125Z"
                fill={primaryColor ?? defaultPrimaryColor}
              />
            </svg>
          </button> */}
        </MobileNavigation>
      )}
    </ProgressBarContainer>
  );
};

export default ProgressBar;
