import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import { useTranslations } from "hooks/useTranslations";
import { DatePickerContainer } from "./styles";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

const DatePicker: React.FC<LibraryComponentProps> = ({
  slugKey,
  config,
  currentAnswer,
  onAnswerChange,
}) => {
  const { t } = useTranslations();

  const { branding } = useSpiritualAssessmentContext();

  let years: string[] = [];
  for (let i = new Date().getFullYear(); i >= 1922; i--) {
    years.push(i.toString());
  }
  return (
    <DatePickerContainer>
      <div className="calendar-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="21"
          viewBox="0 0 18 21"
          fill="none"
        >
          <path
            d="M5.9375 3H11.5625V1.4375C11.5625 0.929688 11.9531 0.5 12.5 0.5C13.0078 0.5 13.4375 0.929688 13.4375 1.4375V3H15C16.3672 3 17.5 4.13281 17.5 5.5V18C17.5 19.4062 16.3672 20.5 15 20.5H2.5C1.09375 20.5 0 19.4062 0 18V5.5C0 4.13281 1.09375 3 2.5 3H4.0625V1.4375C4.0625 0.929688 4.45312 0.5 5 0.5C5.50781 0.5 5.9375 0.929688 5.9375 1.4375V3ZM1.875 10.1875H5V8H1.875V10.1875ZM1.875 12.0625V14.5625H5V12.0625H1.875ZM6.875 12.0625V14.5625H10.625V12.0625H6.875ZM12.5 12.0625V14.5625H15.625V12.0625H12.5ZM15.625 8H12.5V10.1875H15.625V8ZM15.625 16.4375H12.5V18.625H15C15.3125 18.625 15.625 18.3516 15.625 18V16.4375ZM10.625 16.4375H6.875V18.625H10.625V16.4375ZM5 16.4375H1.875V18C1.875 18.3516 2.14844 18.625 2.5 18.625H5V16.4375ZM10.625 8H6.875V10.1875H10.625V8Z"
            fill={branding.primaryColor}
          />
        </svg>
      </div>
      <select
        className={`${!currentAnswer?.length ? "greyed" : ""}`}
        value={currentAnswer}
        defaultValue=""
        onChange={(e) => onAnswerChange(e.target.value)}
      >
        <option value="" disabled>
          {t(`${slugKey}.placeHolder`)}
        </option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </DatePickerContainer>
  );
};

export default DatePicker;
