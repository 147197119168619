import { FormItemWrapper, Label } from "./styles";

const FormItem: React.FC<any> = ({ label, children, withoutLabel }) => {
  return (
    <FormItemWrapper>
      {!withoutLabel && <Label>{label}</Label>}
      {children}
    </FormItemWrapper>
  );
};

export default FormItem;
