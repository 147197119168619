import { useMemo, useState } from "react";
import {
  SpiritualAssessment,
  SpiritualAssessmentStep,
  SpiritualAssessmentStepResource,
} from "types/spiritualAssessment";

export type SpiritualAssessmentNavigationHookResult = {
  rawNavigationState: [number, number];
  currentStep: SpiritualAssessmentStep;
  currentResource: SpiritualAssessmentStepResource;
  navigate: (increment: number) => void;
  navigateForward: () => void;
  navigateBackward: () => void;
};
export type SpiritualAssessmentNavigationHook = (
  assessment: SpiritualAssessment
) => SpiritualAssessmentNavigationHookResult;
export const useSpiritualAssessmentNavigation: SpiritualAssessmentNavigationHook =
  (assessment) => {
    const [stepIndex, setStepIndex] = useState(0);
    const [resourceIndex, setResourceIndex] = useState(0);

    const validateNavigationState = (
      step: number,
      resource: number
    ): "valid" | "outOfRangeHigh" | "outOfRangeLow" => {
      if (assessment?.steps[step]?.resources?.[resource]) {
        return "valid";
      } else if (resource >= assessment?.steps[step].resources.length) {
        return "outOfRangeHigh";
      } else {
        return "outOfRangeLow";
      }
    };

    const navigate = (increment: number) => {
      if (increment === 0) return;

      let currentStepIndex = stepIndex;
      let currentResourceIndex = resourceIndex;
      let isBackwardsNavigation = increment < 0;

      for (let i = 0; i < Math.abs(increment); i++) {
        currentResourceIndex += isBackwardsNavigation ? -1 : 1;
        const validation = validateNavigationState(
          currentStepIndex,
          currentResourceIndex
        );
        if (validation === "outOfRangeHigh") {
          currentStepIndex++;
          currentResourceIndex = 0;
        } else if (validation === "outOfRangeLow") {
          currentStepIndex--;
          currentResourceIndex =
            assessment.steps[currentStepIndex].resources.length - 1;
        }
      }

      setStepIndex(currentStepIndex);
      setResourceIndex(currentResourceIndex);
    };

    return {
      rawNavigationState: [stepIndex, resourceIndex] as [number, number],
      currentStep: useMemo(
        () => assessment?.steps[stepIndex],
        [assessment, stepIndex]
      ),
      currentResource: useMemo(
        () => assessment?.steps[stepIndex].resources[resourceIndex],
        [assessment, resourceIndex]
      ),
      navigate,
      navigateForward: () => navigate(1),
      navigateBackward: () => navigate(-1),
    };
  };
