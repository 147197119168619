import React, { PropsWithChildren } from "react";
import { Wrapper, Content, Media, Header, Body, Footer } from "./styles";

type CardProps = PropsWithChildren<{}>;
type CardContentProps = PropsWithChildren<{}>;
type CardMediaProps = PropsWithChildren<{}>;
type CardHeaderProps = PropsWithChildren<{}>;
type CardBodyProps = PropsWithChildren<{}>;
type CardFooterProps = PropsWithChildren<{}>;

const Card = ({ children, ...restProps }: CardProps) => {
  return <Wrapper {...restProps}>{children}</Wrapper>;
};
Card.Content = function CardContent({
  children,
  ...restProps
}: CardContentProps) {
  return <Content {...restProps}>{children}</Content>;
};
Card.Media = function CardMedia({ children, ...restProps }: CardMediaProps) {
  return <Media {...restProps}>{children}</Media>;
};
Card.Header = function CardHeader({ children, ...restProps }: CardHeaderProps) {
  return <Header {...restProps}>{children}</Header>;
};
Card.Body = function CardBody({ children, ...restProps }: CardBodyProps) {
  return <Body {...restProps}>{children}</Body>;
};
Card.Footer = function CardFooter({ children, ...restProps }: CardFooterProps) {
  return <Footer {...restProps}>{children}</Footer>;
};

export default Card;
