import { SpiritualAssessmentContext } from "hooks/context/spiritualAssessment/spiritualAssessmentContext";
import { InteractionTrackingContext } from "hooks/context/interactionTracking/interactionTrackingContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./app.css";
import SpiritualAssessment from "components/spiritualAssessment/spiritualAssessment";
import { SpiritualAssessmentResourceData } from "types/spiritualAssessment";
import { SpiritualAssessmentAnswer } from "hooks/context/spiritualAssessment/useSpiritualAssessmentAnswers";

export type LayoutProps = {
  step: number;
  slugs: string[];
  config: SpiritualAssessmentResourceData[];
  onNextClick: () => void;
  onBackClick: () => void;
  currentAnswerMap: { [key: string]: SpiritualAssessmentAnswer };
  onAnswerChange: (key: string, answer: any) => void;
};
export type ComponentConfigProps = any;

function AppWithContext() {
  return (
    <Routes>
      <Route index element={<SpiritualAssessment />} />
      <Route path=":partner">
        <Route index element={<SpiritualAssessment />} />
        <Route path=":language" element={<SpiritualAssessment />} />
      </Route>
    </Routes>
  );
}

function App() {
  return (
    <BrowserRouter>
      <InteractionTrackingContext>
        <SpiritualAssessmentContext>
          <AppWithContext />
        </SpiritualAssessmentContext>
      </InteractionTrackingContext>
    </BrowserRouter>
  );
}

export default App;
