import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";
import { useTranslations } from "hooks/useTranslations";
import { CheckboxGroupWrapper, Input, Label } from "./styles";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

export type CheckboxGroupProps = {
  slugKey: string;
  options: any[];
  primaryColor?: string;
  direction?: string;
};
const CheckboxGroup: React.FC<LibraryComponentProps> = ({
  config,
  slugKey,
  currentAnswer,
  onAnswerChange,
}) => {
  const { t } = useTranslations();
  const { branding } = useSpiritualAssessmentContext();

  const { options, direction } = config;

  const toggleCheckbox: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    if (target.checked) {
      onAnswerChange([...(currentAnswer ?? []), target.value]);
    } else {
      const newitemsChecked = [...(currentAnswer ?? [])].filter(
        (x) => x !== target.value
      );
      onAnswerChange(newitemsChecked);
    }
  };

  return (
    <CheckboxGroupWrapper direction={direction}>
      {options &&
        options.map((option: string, i: any) => (
          <Label key={i}>
            <Input
              primaryColor={branding.primaryColor}
              value={option}
              type="checkbox"
              onChange={toggleCheckbox}
            />
            {t(`${slugKey}.${option}`)}
          </Label>
        ))}
    </CheckboxGroupWrapper>
  );
};

export default CheckboxGroup;
