import { SlideGradient } from "./styles";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

type SlideProps = {
  children: React.ReactNode;
};

const Slide: React.FC<SlideProps> = ({ children }) => {
  const { branding } = useSpiritualAssessmentContext();
  return (
    <SlideGradient
      primaryColor={branding.primaryColor}
      secondaryColor={branding.secondaryColor}
    >
      {/* We will grab the image url from each partner's data, will be uploaded to S3 */}
      {/* <SlideBackground src={backgroundDesktop} /> */}
      {children}
    </SlideGradient>
  );
};

export default Slide;
