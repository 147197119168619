import styled from "styled-components";
type CheckboxGroupWrapperProps = {
  direction?: string;
};
export const CheckboxGroupWrapper = styled.div<CheckboxGroupWrapperProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.direction === "horizontal" ? "repeat(auto-fill, 170px)" : "1fr"};
  gap: 20px;

  @media screen and (max-width: 1024px) {
    gap: 1rem;
  }
`;

export const Label = styled.label`
  width: fit-content;
`;

type InputProps = {
  primaryColor: string;
};
export const Input = styled.input<InputProps>`
  margin: 0;
  margin-right: 10px;
  visibility: hidden;
  :after {
    width: 15px;
    height: 15px;
    top: 0;
    left: 0;
    position: relative;
    background-color: white;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid #d7d7d7;
  }
  :checked:after {
    background-color: ${(props) => props.primaryColor};
  }
`;
