import styled from "styled-components";

export const Input = styled.input`
  font-family: inherit;
  box-sizing: border-box;
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  outline: none;
  padding: 0 20px;
`;
