import { useTranslations } from "hooks/useTranslations";
import { RadioProps } from "library/comboRadio/comboRadio";
import { useEffect, useState } from "react";
import { RadioSliderContainer } from "./styles";
import { useSpiritualAssessmentContext } from "../../hooks/context/spiritualAssessment/spiritualAssessmentContext";

const RangeInput: React.FC<RadioProps> = (props) => {
  const { t } = useTranslations();
  const { branding } = useSpiritualAssessmentContext();

  const { config } = props;
  const { options } = config;
  const [sliderTrackWidth, setSliderTrackWidth] = useState("0.00%");

  /* useEffect(() => {
    onAnswerChange(options![valueChecked]);
  }, [valueChecked]);
 */
  useEffect(() => {
    handleInputChange(props.valueChecked);
  }, [props.valueChecked]);

  const handleInputChange = (newValue: number) => {
    // const newValue = event.currentTarget.value;
    props.setValueChecked(Number(newValue));

    const newSliderWidthPercentage = calculateSliderTrackWidth(
      Number(newValue)
    );
    setSliderTrackWidth(newSliderWidthPercentage);
  };

  const calculateSliderTrackWidth = (newValue: number) => {
    const numDivisions = options!.length - 1;
    const percentage = (newValue / numDivisions) * 100;
    return percentage.toString() + "%";
  };
  const isDesktop = window.innerWidth > 1024;
  return (
    <RadioSliderContainer
      primaryColor={branding.primaryColor}
      sliderTrackWidth={sliderTrackWidth}
    >
      {isDesktop && (
        <div className="range-container">
          <div className="range">
            <input
              type="range"
              id="options"
              name="options"
              min="0"
              max={options!.length - 1}
              step="1"
              value={props.valueChecked}
              onChange={({ target }) => handleInputChange(Number(target.value))}
            />
          </div>
          <ul className="range-labels">
            {options &&
              options.map((option: string, index: number) => (
                <li
                  key={index}
                  className={`
                        ${index === props.valueChecked && "active"}
                        ${index < props.valueChecked && "filled"}
                        `}
                >
                  {/* <span>{t(`${slugKey}.${option}`)}</span> */}
                </li>
              ))}
          </ul>
        </div>
      )}
    </RadioSliderContainer>
  );
};

export default RangeInput;
