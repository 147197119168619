import { BackgroundImage } from "./style";

const Background: React.FC<{ children: React.ReactNode; url: string }> = ({
  children,
  url,
}) => {
  return <BackgroundImage url={url}>{children}</BackgroundImage>;
};

export default Background;
