import usePreviousState from "hooks/usePreviousState";
import { useEffect } from "react";
import {
  SpiritualAssessmentStep,
  SpiritualAssessmentStepResource,
} from "types/spiritualAssessment";
import { useInteractionTrackingContext } from "../interactionTracking/interactionTrackingContext";
import { SpiritualAssessmentAnswer } from "./useSpiritualAssessmentAnswers";

export const useSpiritualAssessmentInteractions = (
  currentStep: SpiritualAssessmentStep,
  currentResource: SpiritualAssessmentStepResource,
  currentAnswerMap: {
    [key: string]: SpiritualAssessmentAnswer;
  }
) => {
  const { interactions } = useInteractionTrackingContext();

  const previousStep = usePreviousState<SpiritualAssessmentStep>(currentStep);
  const previousResource =
    usePreviousState<SpiritualAssessmentStepResource>(currentResource);
  const previousAnswerMap = usePreviousState<{
    [key: string]: SpiritualAssessmentAnswer;
  }>(currentAnswerMap);

  useEffect(() => {
    if (previousStep) {
      interactions.createEvent("status", previousStep.slug, {
        status: "completed",
        type: "step",
      });
    }
    if (currentStep) {
      interactions.createEvent("status", currentStep.slug, {
        status: "started",
        type: "step",
      });
    }
  }, [currentStep]);

  useEffect(() => {
    if (previousStep && previousResource) {
      interactions.createEvent(
        "status",
        `${previousStep.slug}.${previousResource.slug}`,
        {
          status: "completed",
          type: "resource",
          responses: previousAnswerMap,
        }
      );
    }
    if (currentStep && currentResource) {
      interactions.createEvent(
        "status",
        `${currentStep.slug}.${currentResource.slug}`,
        {
          status: "started",
          type: "resource",
        }
      );
    }
  }, [currentResource]);
};
