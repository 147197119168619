import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { LibraryComponentProps } from "components/libraryComponent/libraryComponent";

import {
  CircularProgressbarItem,
  CircularProgressbarList,
  Label,
  Percentage,
} from "./styles";
import { useTranslations } from "hooks/useTranslations";

import { useSpiritualAssessmentContext } from "hooks/context/spiritualAssessment/spiritualAssessmentContext";
import { useResults } from "hooks/useResults";
import { useState } from "react";

const CircularProgressBar: React.FC<LibraryComponentProps> = (props) => {
  const { t } = useTranslations();
  const { config, slugKey } = props;
  const { options } = config;
  const { assessment, answers, branding } = useSpiritualAssessmentContext();
  const getScores = useResults(assessment!, answers, options);
  const [scores] = useState(getScores());

  const pathColor = branding.accentColor;

  return (
    <CircularProgressbarList>
      {options &&
        options.map((option: string) => (
          <div key={`${slugKey}.${option}`}>
            <CircularProgressbarItem>
              <CircularProgressbarWithChildren
                value={scores[option]}
                strokeWidth={10}
                styles={buildStyles({
                  pathColor: pathColor,
                  trailColor: `${pathColor}42`,
                  strokeLinecap: "butt",
                })}
              >
                <Percentage>{scores[option]}%</Percentage>
                <Label>{t(`${slugKey}.${option}`)}</Label>
              </CircularProgressbarWithChildren>
            </CircularProgressbarItem>
            <p>{t(`${slugKey}.${option}.label`)}</p>
          </div>
        ))}
    </CircularProgressbarList>
  );
};
export default CircularProgressBar;
