import styled from "styled-components";

export const CheckboxGroupContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 4rem;
  padding-top: 52px;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

type CheckboxItemProps = {
  primaryColor: string;
  primaryColorLight: string;
};
export const CheckboxItem = styled.div<CheckboxItemProps>`
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 4rem 2rem 1rem;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 232px;

  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: transparent;
    border-radius: 12px;
    border: none;
    z-index: 1;
    cursor: pointer;
  }
  input:checked + label {
    background: ${(props) => props.primaryColorLight};
  }

  input {
    z-index: 2;
    margin: 0;
    visibility: hidden;

    :after {
      width: 15px;
      height: 15px;
      top: 0;
      left: 0;
      position: relative;
      background-color: white;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 1px solid #d7d7d7;
    }
    :checked:after {
      background-color: ${(props) => props.primaryColor};
    }
  }

  .checkbox-icon {
    position: absolute;
    top: -52px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    width: 104px;
    height: 104px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    z-index: 2;

    img {
      width: 78px;
    }
  }

  p {
    margin: 0;
    z-index: 2;
  }

  @media screen and (max-width: 767px) {
    min-height: 210px;
    padding: 3rem 1.5rem 1rem;

    .checkbox-icon {
      top: -32px;
      width: 64px;
      height: 64px;

      img {
        width: 48px;
      }
    }
  }
`;
