//@ts-nocheck
import { useState } from "react";
import Button from "../../library/button";
import Card from "../../library/card";
import FormItem from "../../library/formItem";
import PageHeader from "../../library/pageHeader";
import StepsBar from "../../library/stepsBar";
import Slide from "../../library/slide";
import VideoPlayer from "../../library/videoPlayer";
import { LayoutProps } from "components/layout/layout";
import LibraryComponent from "components/libraryComponent/libraryComponent";
import { useTranslations } from "hooks/useTranslations";

const MediaLayout: React.FC<LayoutProps> = (props) => {
  const [secondsPlayed, setSecondsPlayed] = useState(5);
  const { t } = useTranslations();
  const slugKey = props.slugs.join(".");
  const DEFAULT_URL =
    "https://player.vimeo.com/progressive_redirect/playback/690687681/rendition/1080p/file.mp4?loc=external&oauth2_token_id=1615415700&signature=13afc2a5afa30836084f4bb8a9c8ff3631d660a233133eca814c5f7b242c666b";
  const mediaResource = props.config.find(
    (data) => data.type === "video" || data.type === "image"
  );
  const onVideoProgress = ({ playedSeconds }) => {
    if (secondsPlayed >= 0) {
      setSecondsPlayed(5 - playedSeconds);
    }
  };
  return (
    <Slide>
      <StepsBar stepIndex={props.step} steps={props.steps} />

      <Card>
        <Card.Content>
          <Card.Header>
            <PageHeader
              title={t(`${slugKey}.title`)!}
              subtitle={t(`${slugKey}.text`)}
            />
          </Card.Header>
          {mediaResource?.type !== "video" && (
            <Card.Body>
              {props.config.map((item) => (
                <FormItem
                  key={`${slugKey}.${item.variable}`}
                  label={t(`${slugKey}.${item.variable}`)}
                  withoutLabel={item.withoutLabel}
                >
                  <LibraryComponent
                    config={item}
                    slugKey={`${slugKey}.${item.variable}`}
                    currentAnswer={props.currentAnswerMap?.[item.variable]}
                    onAnswerChange={(answer) =>
                      props.onAnswerChange(item.variable, answer)
                    }
                  />
                </FormItem>
              ))}
            </Card.Body>
          )}
          {mediaResource?.type === "video" && (
            <Card.Footer>
              {!props.lastResource && (
                <Button
                  disabled={!(secondsPlayed <= 0)}
                  onClick={props.onNextClick}
                  hasCounter={true}
                  time={secondsPlayed}
                  label={t(`${slugKey}.button`)}
                ></Button>
              )}
            </Card.Footer>
          )}
        </Card.Content>
        <Card.Media>
          {mediaResource?.type === "video" ? (
            <VideoPlayer
              url={mediaResource?.src || DEFAULT_URL}
              onProgress={onVideoProgress}
            ></VideoPlayer>
          ) : (
            <img src={mediaResource?.src} alt="media content" />
          )}
        </Card.Media>
      </Card>
    </Slide>
  );
};
export default MediaLayout;
