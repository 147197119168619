import styled from "styled-components";
type RadioButtonWrapperProps = {
  direction?: string;
};
export const RadioButtonWrapper = styled.div<RadioButtonWrapperProps>`
  /* display: flex;
  flex-direction: column;
  gap: 1rem; */
  display: grid;
  grid-template-columns: ${(props) =>
    props.direction === "horizontal" ? "repeat(auto-fill, 111px)" : "1fr"};
  gap: 20px;
`;

type InputProps = {
  primaryColor: string;
};
export const Label = styled.label`
  display: flex;
  alignitems: center;
  width: fit-content;
  p {
    margin: 0;
  }
`;
export const Input = styled.input<InputProps>`
  margin: 0;
  margin-right: 10px;
  visibility: hidden;
  :after {
    width: 12px;
    height: 12px;
    border-radius: 15px;
    top: 3px;
    left: 0;
    position: relative;
    background-color: white;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid #d7d7d7;
  }
  :checked:after {
    background-color: ${(props) => props.primaryColor};
  }
`;
