import styled from "styled-components";

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  h1,
  p {
    text-align: left;
    margin: 0;
    color: #222;
  }
`;
export const Title = styled.h1`
  font-family: "NoirProBold";
  font-size: 50px;
  @media only screen and (max-width: 768px) {
    font-size: 30px;
  }
`;
export const Subtitle = styled.p`
  margin-top: 20px !important;
  font-family: "NoirProMedium";
  font-size: 20px;
`;
