import RadioSlider from "../../library/radioSlider";
import RadioButton from "../../library/radioButton";
import DatePicker from "../../library/datePicker";
import IconRadioButton from "../../library/iconRadioButton/iconRadioButton";
import TextInput from "../../library/textInput";
import IconCheckboxGroup from "../../library/iconCheckboxGroup/iconCheckboxGroup";
import CheckboxGroup from "../../library/checkboxGroup";
import CircularProgressBar from "../../library/circularProgressBar";
import PhoneInput from "../../library/phoneInput";
import NoteParagraph from "../../library/noteParagraph";
import { SpiritualAssessmentResourceData } from "types/spiritualAssessment";
import ComboRadio from "library/comboRadio/comboRadio";
import PasswordInput from "library/passwordInput";

export type LibraryComponentProps = {
  slugKey: string;
  config: SpiritualAssessmentResourceData;
  currentAnswer: any;
  onAnswerChange: (answer: any) => void;
};

const LibraryComponent: React.FC<LibraryComponentProps> = (props) => {
  switch (props.config.type) {
    case "RadioButton":
      return <RadioButton {...props} />;
    case "ComboRadio":
      return <ComboRadio {...props} />;
    case "DatePicker":
      return <DatePicker {...props} />;
    case "RadioSlider":
      return <RadioSlider {...props} />;
    case "IconRadioButton":
      return <IconRadioButton {...props} />;
    case "TextInput":
      return <TextInput {...props} />;
    case "IconCheckboxGroup":
      return <IconCheckboxGroup {...props} />;
    case "CheckboxGroup":
      return <CheckboxGroup {...props} />;
    case "CircularProgressBar":
      return <CircularProgressBar {...props} />;
    case "PhoneInput":
      return <PhoneInput {...props} />;
    case "NoteParagraph":
      return <NoteParagraph {...props} />;
    case "PasswordInput":
      return <PasswordInput {...props} />;
    default:
      return null;
  }
};

export default LibraryComponent;
