import styled from "styled-components";

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  height: 100%;
`;

export const MediaButton = styled.div`
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  text-align: center;
  z-index: 1;
  svg {
    left: calc(50%);
    top: calc(50%);
    width: 100px;
    height: 100px;

    .circle {
      fill: ${(props) => props.color};
    }
    .play {
      fill: #ffffff;
    }
    .pause {
      fill: #ffffff;
    }
  }
  svg:hover {
    .circle {
      fill: #ffffff;
    }
    .play {
      fill: ${(props) => props.color};
    }
    .pause {
      fill: ${(props) => props.color};
    }
  }

  @media screen and (max-width: 1024px) {
    #pauseButton {
      display: none;
    }
  }
`;
